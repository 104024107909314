import { ProSidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Actions from 'redux/actions/index';
import { bindActionCreators } from 'redux';
import 'react-pro-sidebar/dist/css/styles.css';

import { Icon, ICONS } from 'components/Icons';
import { AWSCognitoInstance } from 'services/AWSCognito';

import './SideBar.scss';

function SideBar({ pathname, username, actions }) {
  return (
    <ProSidebar>
      <Menu iconShape="square">
        <MenuItem active={pathname === '/dashboard' ? true : false}>
          <div className="d-flex align-items-center">
            <Icon icon={ICONS.ACTIVITY} size={24} className="mr-2" />
            <span className="menu-item-name">Dashboard</span>
          </div>
          <Link to="/dashboard" />
        </MenuItem>
        <MenuItem active={pathname === '/users' ? true : false}>
          <div className="d-flex align-items-center">
            <Icon icon={ICONS.USERS} size={24} className="mr-2" />
            <span className="menu-item-name">Users</span>
          </div>
          <Link to="/users" />
        </MenuItem>
        {/* <MenuItem active={pathname === '/curation' ? true : false}>
          <div className="d-flex align-items-center">
            <Icon icon={ICONS.CURATION} size={24} className="mr-2" />
            <span className="menu-item-name">Curation</span>
          </div>
          <Link to="/curation" />
        </MenuItem> */}
        {/* <MenuItem active={pathname === '/sourceClassifier' ? true : false}>
          <div className="d-flex align-items-center">
            <Icon icon={ICONS.CLASSIFY} size={24} className="mr-2" />
            <span className="menu-item-name">Article Classifier</span>
          </div>
          <Link to="/sourceClassifier" />
        </MenuItem> */}
        <MenuItem active={pathname === '/articles' ? true : false}>
          <div className="d-flex align-items-center">
            <Icon icon={ICONS.CLASSIFY} size={24} className="mr-2" />
            <span className="menu-item-name">Articles</span>
          </div>
          <Link to="/articles" />
        </MenuItem>
        {/* <MenuItem active={pathname === '/subscription' ? true : false}>
          <div className="d-flex align-items-center">
            <Icon icon={ICONS.APPROVED} size={24} className="mr-2" />
            <span className="menu-item-name">Subscription</span>
          </div>
          <Link to="/subscription" />
        </MenuItem> */}
        <MenuItem active={pathname === '/manageSources' ? true : false}>
          <div className="d-flex align-items-center">
            <Icon icon={ICONS.SOURCE} size={24} className="mr-2" />
            <span className="menu-item-name">Sources</span>
          </div>
          <Link to="/manageSources" />
        </MenuItem>
        <MenuItem active={pathname === '/classroom' ? true : false}>
          <div className="d-flex align-items-center">
            <Icon icon={ICONS.CLASSROOM} size={24} className="mr-2" />
            <span className="menu-item-name">Classroom</span>
          </div>
          <Link to="/classroom" />
        </MenuItem>
        {/* <MenuItem active={pathname === '/requestedSources' ? true : false}>
          <div className="d-flex align-items-center">
            <Icon icon={ICONS.DOMAIN} size={24} className="mr-2" />
            <span className="menu-item-name">Requested Sources</span>
          </div>
          <Link to="/requestedSources" />
        </MenuItem> */}
        {/* <MenuItem active={pathname === '/sourceErrors' ? true : false}>
          <div className="d-flex align-items-center">
            <Icon icon={ICONS.SOURCE} size={24} className="mr-2" />
            <span className="menu-item-name">Source Errors</span>
          </div>
          <Link to="/sourceErrors" />
        </MenuItem> */}
        {/* <MenuItem active={pathname === '/manageArticles' ? true : false}>
          <div className="d-flex align-items-center">
            <Icon
              icon={ICONS.DOCUMENT_TEXT_OUTLINE}
              size={24}
              className="mr-2"
            />
            <span className="menu-item-name">Articles</span>
          </div>
          <Link to="/manageArticles" />
        </MenuItem> */}
        {/* <MenuItem active={pathname === '/manageDuplicatePoints' ? true : false}>
          <div className="d-flex align-items-center">
            <Icon icon={ICONS.POINT} size={24} className="mr-2" />
            <span className="menu-item-name">Duplicate Points</span>
          </div>
          <Link to="/manageDuplicatePoints" />
        </MenuItem> */}
        {/* <MenuItem active={pathname === '/verifyUserRoles' ? true : false}>
          <div className="d-flex align-items-center">
            <Icon icon={ICONS.USER} size={24} className="mr-2" />
            <span className="menu-item-name">Verify Classroom Roles</span>
          </div>
          <Link to="/verifyUserRoles" />
        </MenuItem> */}
        <MenuItem active={pathname === '/blogs' ? true : false}>
          <div className="d-flex align-items-center">
            <Icon icon={ICONS.LIST} size={24} className="mr-2" />
            <span className="menu-item-name">Blogs</span>
          </div>
          <Link to="/blogs" />
        </MenuItem>
        {/* <MenuItem active={pathname === '/topicKeywords' ? true : false}>
          <div className="d-flex align-items-center">
            <Icon icon={ICONS.TOPICS} size={24} className="mr-2" />
            <span className="menu-item-name">Manage topic keywords</span>
          </div>
          <Link to="/topicKeywords" />
        </MenuItem> */}
        <MenuItem active={pathname === '/disputedQuizQuestions' ? true : false}>
          <div className="d-flex align-items-center">
            <Icon icon={ICONS.QUESTION} size={24} className="mr-2" />
            <span className="menu-item-name">Disputed Quiz Questions</span>
          </div>
          <Link to="/disputedQuizQuestions" />
        </MenuItem>
        {/* <MenuItem active={pathname === '/writingBackups' ? true : false}>
          <div className="d-flex align-items-center">
            <Icon icon={ICONS.PENCIL} className="mr-3" width={18} height={18} />
            <span className="menu-item-name">Writing Backups</span>
          </div>
          <Link to="/writingBackups" />
        </MenuItem> */}
        {/* <MenuItem active={pathname === '/debugLogs' ? true : false}>
          <div className="d-flex align-items-center">
            <Icon
              icon={ICONS.DOCUMENT_TEXT_OUTLINE}
              size={24}
              className="mr-2"
            />
            <span className="menu-item-name">Debug Logs</span>
          </div>
          <Link to="/debugLogs" />
        </MenuItem> */}
        {/* <MenuItem active={pathname === '/admin-courses' ? true : false}>
          <div className="d-flex align-items-center">
            <Icon icon={ICONS.CLASSROOM} size={24} className="mr-2" />
            <span className="menu-item-name">Courses</span>
          </div>
          <Link to="/admin-courses" />
        </MenuItem> */}
        <MenuItem
          onClick={() => {
            AWSCognitoInstance.logout(username);
            actions.manualLogout(pathname);
          }}
        >
          <div className="d-flex align-items-center">
            <Icon icon={ICONS.LOGOUT} size={24} className="mr-2" />
            <span className="menu-item-name">Logout</span>
          </div>
        </MenuItem>
        {/* <SubMenu title="Settings" icon={<Icon icon={ICONS.HELP} size={24} />}>
          <MenuItem>Settings 1</MenuItem>
          <MenuItem>Settings 2</MenuItem>
        </SubMenu> */}
      </Menu>
    </ProSidebar>
  );
}

function mapStateToProps(state) {
  const { user } = state.user;

  return {
    username: user && user.username,
  };
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
