import React, { Component } from 'react';
//import MoreInfoDropDown from "./MoreInfoDropDown";

import DropDown from 'components/elements/molecules/DropDown/DropDown';

import { Icon, ICONS } from 'components/Icons';
import ProfileImage from 'components/elements/molecules/Images/profileImage';

import './MoreInfo.scss';
import { Link } from 'react-router-dom';

class MoreInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMoreInfo: false,
      showModal: false,
      modalToShow: '',
    };
  }

  componentDidMount() {
    document.body.addEventListener(
      'click',
      this.onBodyClickedOutsideMoreInfo,
      false,
    );
  }

  componentWillUnmount() {
    document.body.removeEventListener(
      'click',
      this.onBodyClickedOutsideMoreInfo,
    );
  }

  onBodyClickedOutsideMoreInfo = (e) => {
    const target = e.target;
    const content_area = document.getElementById('more-info-container');
    const controlsicon = document.getElementById('more-info');

    if (
      target !== controlsicon &&
      target !== content_area &&
      !this.isChildOf(target, content_area) &&
      !this.isChildOf(target, controlsicon)
    ) {
      this.setState({
        showMoreInfo: false,
      });
    }
  };

  isChildOf = (child, parent) => {
    if (child.parentNode === parent) {
      return true;
    } else if (child.parentNode === null) {
      return false;
    }
    return this.isChildOf(child.parentNode, parent);
  };

  toggleMoreInfo = () => {
    this.setState({
      showMoreInfo: !this.state.showMoreInfo,
    });
  };

  hideMoreInfo = () => {
    this.setState({
      showMoreInfo: false,
    });
  };

  logoutAction = () => {
    this.props.onLogoutClicked();
    this.hideMoreInfo();
  };

  render() {
    const menuItems = [
      {
        label: (
          <Link
            to={'/portfolio/' + this.props.user.username}
            className="d-flex align-items-center w-100"
          >
            <ProfileImage
              userId={this.props.user._id}
              photoLicenseTerms={this.props.user.photoLicenseTerms}
              hostedImageVersion={this.props.user.hostedImageVersion}
              size={30}
              allowUserToChangePic={false}
              username={this.props.user.username}
            />
            <span className="username">{this.props.user.username}</span>
          </Link>
        ),
        className: 'profile',
      },
      {
        label: 'Blogs',
        className: 'link',
        onClick: () => {
          window.open('https://learn.averpoint.com/blogs', '_blank');
        },
      },
      {
        label: 'Invite',
        className: 'link',
        onClick: this.props.inviteUser,
      },
      {
        label: 'Settings',
        className: 'link',
        onClick: () => {
          this.props.goToPage('/settings');
        },
      },
      {
        label: 'About',
        className: 'link',
        onClick: () => {
          this.props.goToPage('/about');
        },
      },
      {
        label: 'Help',
        className: 'link',
        onClick: () => {
          this.props.goToPage('/help');
        },
      },
      {
        label: 'Sources',
        className: 'link',
        onClick: () => {
          this.props.goToPage('/sources');
        },
      },
      {
        label: 'Levels',
        className: 'link',
        onClick: () => {
          this.props.goToPage('/levels');
        },
      },
      {
        label: 'Contact us',
        className: 'link',
        onClick: () => {
          this.props.goToPage('/contact');
        },
      },
      {
        label: 'Logout',
        className: 'link logout',
        onClick: this.logoutAction,
        showDivider: true,
      },
    ];

    return (
      <DropDown
        menuItems={menuItems}
        containerClassName={'more-info-container'}
        componentClassName={'more-info'}
        iconName={'MORE'}
        iconClassName={'more-info-icon'}
        menuClassName={'more-info-dropdown'}
      />
    );
  }
}

export default MoreInfo;
