import RectangularButton from 'components/elements/molecules/RectangularButton/RectangularButton';

import './TopicSelection.scss';

export default function TopicSelectButton({
  topics,
  selectedTopics,
  predictedTopics,
  setTopics,
  setPredictedTopics,
}) {
  const selectedTopicsList = Array.isArray(selectedTopics)
    ? selectedTopics
    : [];

  const handleTopicChange = (topic) => {
    if (selectedTopicsList.includes(topic)) {
      if (predictedTopics && predictedTopics.includes(topic)) {
        const topicIndex = predictedTopics.indexOf(topic);
        const newTopics = [...predictedTopics];
        newTopics.splice(topicIndex, 1);
        setPredictedTopics(newTopics);
      }

      setTopics(selectedTopicsList.filter((prevTopic) => prevTopic !== topic));
      return;
    }

    setTopics([...selectedTopicsList, topic]);
  };

  const buttonClass = (topic) => {
    console.log('');

    if (selectedTopicsList.includes(topic)) {
      return 'selected-topic';
    }

    if (predictedTopics && predictedTopics.includes(topic)) {
      return 'predicted-topic';
    }

    return '';
  };

  return (
    <div className="topic-selection">
      {topics
        .sort((b, a) => b.localeCompare(a))
        .map((topic) => (
          <div className="topic-button">
            <RectangularButton
              onClick={() => handleTopicChange(topic)}
              lightButton
              className={`my-2 ${buttonClass(topic)}`}
            >
              {topic}
            </RectangularButton>
          </div>
        ))}
    </div>
  );
}
