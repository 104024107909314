import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import './css/DropDown.scss';

class DropDown extends Component {
  static propTypes = {
    id: PropTypes.string,
    labelName: PropTypes.string,
    value: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    className: PropTypes.string,
    isMulti: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isClearable: PropTypes.bool,
    options: PropTypes.arrayOf(
      PropTypes.shape({ value: PropTypes.string, label: PropTypes.string }),
    ),
    controlStyle: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setState({
        value: this.props.value,
      });
    }
  }

  render() {
    const { props } = this;

    return (
      <div className={`a-drop-down ${props.className}`}>
        <label>{props.labelName}</label>
        <Select
          defaultValue={props.defaultValue}
          value={props.value}
          onChange={this.onChange}
          options={props.options}
          isMulti={props.isMulti}
          maxMenuHeight={300}
          isDisabled={props.isDisabled}
          isClearable={props.isClearable}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              border: '0px',
              borderBottom: '1px solid #b7c6cf',
              borderRadius: 0,
              borderColor: '#FFFFFF',
              outline: 'none',
              boxShadow: 'none',
              '&:hover': {
                outline: 'none',
                boxShadow: 'none',
                backgroundColor: '#e8f1f8',
              },
              cursor: 'pointer',
              ...props.controlStyle,
            }),
            indicatorSeparator: (baseStyles, state) => ({
              ...baseStyles,
              width: '0px',
            }),
            menu: (baseStyles, state) => ({
              ...baseStyles,
              margin: '0px',
              borderRadius: '0px',
              zIndex: 100,
            }),
          }}
        />
      </div>
    );
  }

  onChange = (selectedOption) => {
    this.setState({
      value: selectedOption,
    });
    if (this.props.onChange) this.props.onChange(selectedOption);
  };
}

export default DropDown;
