import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import './css/DropDownWithAction.scss';
import { Icon, ICONS } from 'components/Icons';

class DropDownWithAction extends Component {
  static propTypes = {
    id: PropTypes.string,
    labelName: PropTypes.string,
    value: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    isMulti: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isClearable: PropTypes.bool,
    options: PropTypes.arrayOf(
      PropTypes.shape({ value: PropTypes.string, label: PropTypes.string }),
    ),
    controlStyle: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
      menuOpen: false,
      searchText: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setState({
        value: this.props.value,
      });
    }
  }

  showSearch() {
    if (!this.state.menuOpen) {
      return null;
    }

    return (
      <div
        className="drop-down-search"
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
        }}
      >
        <Icon icon={ICONS.SEARCH} className="av-gray" />
        <input
          type="text"
          autoFocus={true}
          value={this.state.searchText}
          onChange={(e) => this.setState({ searchText: e.target.value })}
        />
        {this.props.handleCreate && (
          <span
            className="av-blue cursor-pointer"
            onClick={() => {
              this.props.handleCreate();
              this.setState({ menuOpen: false });
            }}
          >
            + Create
          </span>
        )}
      </div>
    );
  }

  render() {
    const { props } = this;

    return (
      <div className={`a-drop-down a-drop-down-with-action ${props.className}`}>
        {this.state.menuOpen && (
          <div
            className="backdrop"
            onClick={() => this.setState({ menuOpen: false })}
          ></div>
        )}
        <label>{props.labelName}</label>
        <Select
          defaultValue={props.defaultValue}
          value={props.value}
          onChange={this.onChange}
          options={props.options}
          isMulti={props.isMulti}
          maxMenuHeight={300}
          isDisabled={props.isDisabled}
          isClearable={props.isClearable}
          closeMenuOnSelect
          menuIsOpen={this.state.menuOpen}
          onMenuOpen={() => {
            this.setState({
              menuOpen: true,
            });
          }}
          filterOption={(option) => {
            return (
              !this.state.searchText ||
              option.label
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase())
            );
          }}
          isSearchable={!this.state.menuOpen}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              border: '0px',
              borderBottom: '1px solid #b7c6cf',
              borderRadius: 0,
              borderColor: '#FFFFFF',
              outline: 'none',
              boxShadow: 'none',
              '&:hover': {
                outline: 'none',
                boxShadow: 'none',
                backgroundColor: '#e8f1f8',
              },
              cursor: 'pointer',
              ...props.controlStyle,
            }),
            indicatorSeparator: (baseStyles, state) => ({
              ...baseStyles,
              width: '0px',
            }),
            menu: (baseStyles, state) => ({
              ...baseStyles,
              margin: '0px',
              borderRadius: '0px',
            }),
            valueContainer: (baseStyles) => ({
              ...baseStyles,
              padding: '2px 0px',
            }),
          }}
          placeholder={props.placeholder || 'Select'}
        />
        {this.showSearch()}
      </div>
    );
  }

  onChange = (selectedOption) => {
    this.setState({
      value: selectedOption,
      menuOpen: false,
    });
    if (this.props.onChange) this.props.onChange(selectedOption);
  };
}

export default DropDownWithAction;
