import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { debounce } from 'throttle-debounce';

import * as Actions from 'redux/actions/index';
import { Icon, ICONS } from 'components/Icons';
import ActionLink from 'components/elements/molecules/ActionLink/ActionLink';
import PointCard from 'components/cards/Point/Views/PointCard/PointCard';

// SCSS
import './CollectionTree.scss';

class CollectionTree extends Component {
  static propTypes = {
    /**
     * CSS class names
     */
    className: PropTypes.string,
    /**
     * Callback for selection
     */
    selectCollection: PropTypes.func,
    /**
     * Selected Collection/Point ids
     */
    selectedIds: PropTypes.array,
    /**
     * Close button callback. Needed for dropdown close.
     */
    close: PropTypes.func,
    /**
     * Add new collection button.
     */
    onNewCollection: PropTypes.func,
    /**
     * Two modes 1. ALL 2. ONLY_POINTS 3. ONLY_COLLECTIONS
     * Default is ALL
     */
    mode: PropTypes.string,
    /**
     * Default is false
     */
    multiple: PropTypes.bool,
    /**
     * Default is 'Seach Collections'
     */
    searchPlaceholder: PropTypes.string,
    /**
     * Add preview buttons to point. Opens point card on click.
     * Default is false
     */
    showPointPreview: PropTypes.bool,
    /**
     * Add subpoint count.
     * Default is false.
     */
    showSubPointCount: PropTypes.bool,
    expandedCollectionIds: PropTypes.array,
    onToggleExpand: PropTypes.func,
    /**
     * Show sub-points with source URL
     * Default is false.
     */
    showSubPointsWithSourceURL: PropTypes.bool,
  };

  static defaultProps = {
    mode: 'ALL',
    multiple: false,
    searchPlaceholder: 'Search Collections',
    showPointPreview: false,
    showSubPointCount: false,
    showSubPointsWithSourceURL: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      expandedCollectionIds: props.expandedCollectionIds || [],
      expandedGroupIds: [],
      searchTerm: '',
      type: 'MY_COLLECTIONS',
    };

    // Track requested collection/point ids
    this.checkedCollectionIds = [];

    // Track requested group ids
    this.checkedGroupIds = [];

    this.props.actions.getMyGroups();

    this.collectionSearchDebounced = debounce(
      500,
      this.props.actions.collectionSearch,
    );
  }

  componentWillUnmount() {
    this.onSearch('');
  }

  componentDidMount() {
    setTimeout(() => {
      if (this.props.selectedIds && this.props.selectedIds.length === 1) {
        this.scrollViewToPoint(this.props.selectedIds[0]);
      }
    }, 100);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.expandedCollectionIds !== this.props.expandedCollectionIds) {
      this.setState({
        expandedCollectionIds: this.props.expandedCollectionIds,
      });
    }
  }

  scrollViewToPoint(pointId) {
    const point = document.getElementById(`collection-tree-${pointId}`);

    if (point) {
      point.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  isCollectionSelected(collection) {
    const { selectedIds } = this.props;
    return selectedIds.indexOf(collection._id) !== -1;
  }

  onToggleExpand(id) {
    let index = this.state.expandedCollectionIds.findIndex((i) => i === id);
    let expandedCollectionIds;

    if (index === -1) {
      expandedCollectionIds = [...this.state.expandedCollectionIds, id];
    } else {
      expandedCollectionIds = [
        ...this.state.expandedCollectionIds.splice(0, index),
        ...this.state.expandedCollectionIds.splice(
          index + 1,
          this.state.expandedCollectionIds.length,
        ),
      ];
    }

    this.setState({ expandedCollectionIds });

    if (this.props.onToggleExpand) {
      this.props.onToggleExpand(expandedCollectionIds);
    }
  }

  onSelect(collection) {
    const { mode } = this.props;

    if (
      mode !== 'ONLY_POINTS' ||
      (mode === 'ONLY_POINTS' && !collection.isCollection)
    ) {
      this.props.selectCollection(collection._id);
    }
  }

  onSearch(searchTerm) {
    const { type } = this.state;

    this.setState({ searchTerm });

    if (type === 'MY_COLLECTIONS') {
      if (searchTerm) {
        this.collectionSearchDebounced(
          searchTerm,
          'portfolio',
          this.props.user.username,
          null,
          true,
        )
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.props.actions.resetCollectionSearch(
          searchTerm,
          'portfolio',
          this.props.user.username,
        );
      }
    }
  }

  isCollectionExpanded(collection) {
    return (
      this.state.expandedCollectionIds.findIndex(
        (i) => i === collection._id,
      ) !== -1
    );
  }

  // renderClose() {
  //   const { close } = this.props;

  //   if (!close) {
  //     return null;
  //   }

  //   return (
  //     <Icon icon={ICONS.CLOSE} onClick={close} style={{ cursor: 'pointer' }} />
  //   );
  // }

  onGroupToggleExpand(id) {
    let index = this.state.expandedGroupIds.findIndex((i) => i === id);

    if (index === -1) {
      this.setState({
        expandedGroupIds: [...this.state.expandedGroupIds, id],
      });
    } else {
      this.setState({
        expandedGroupIds: [
          ...this.state.expandedGroupIds.splice(0, index),
          ...this.state.expandedGroupIds.splice(
            index + 1,
            this.state.expandedGroupIds.length,
          ),
        ],
      });
    }
  }

  isGroupExpanded(group) {
    return this.state.expandedGroupIds.findIndex((i) => i === group._id) !== -1;
  }

  onChangeType(type) {
    this.setState({ type });
  }

  renderSearch() {
    const { searchPlaceholder } = this.props;

    return (
      <div
        className="d-flex align-items-center search-container av-gray pb-2 pl-3 pr-3 pt-2"
        onClick={(e) => e.stopPropagation()}
      >
        <Icon icon={ICONS.SEARCH} />
        <input
          type="text"
          className="flex-fill ml-3 mr-3"
          placeholder={searchPlaceholder}
          value={this.state.searchTerm}
          onChange={(e) => this.onSearch(e.target.value)}
        />
        {this.renderCreateCollection()}
        {/* {this.renderClose()} */}
      </div>
    );
  }

  renderTypes() {
    const { type } = this.state;
    const myCollectionsClassName = classnames('btn switch-type', {
      active: type === 'MY_COLLECTIONS',
    });
    const myGroupsClassName = classnames('btn switch-type', {
      active: type === 'MY_GROUPS',
    });
    return (
      <div className="d-inline-block">
        <button
          className={myCollectionsClassName}
          onClick={(e) => {
            this.onChangeType('MY_COLLECTIONS');
            e.stopPropagation();
          }}
        >
          My Collections
        </button>
        <button
          className={myGroupsClassName}
          onClick={(e) => {
            this.onChangeType('MY_GROUPS');
            e.stopPropagation();
          }}
        >
          My Groups
        </button>
      </div>
    );
  }

  renderCollapseExpandButton(collection) {
    let isExpanded = this.isCollectionExpanded(collection);

    const className = classnames(
      'expand flex-shrink-0 d-inline-flex align-items-center justify-content-center',
      {
        expanded: isExpanded,
        point: !collection.isCollection || collection.sourceURL,
        collection: collection.isCollection && !collection.sourceURL,
      },
    );

    const iconClassName = classnames({
      add: !isExpanded,
      minus: isExpanded,
    });

    let iconName, iconSize;

    if (collection.isCollection && !collection.sourceURL) {
      if (collection.totalSubPoints) {
        iconName = isExpanded ? 'COLLECTION_COLLAPSE' : 'COLLECTION_EXPAND';
      } else {
        iconName = 'COLLECTION_EMPTY';
      }
      iconSize = 22;
    } else {
      if (collection.totalSubPoints) {
        iconName = isExpanded ? 'MINUS' : 'ADD';
        iconSize = isExpanded ? 14 : 10;
      } else {
        iconName = 'NEWSPAPER_OUTLINE';
        iconSize = 14;
      }
    }

    return (
      <div className={className}>
        {/* {collection.totalSubPoints ? ( */}
        <ActionLink
          onClick={() => {
            if (collection.totalSubPoints) {
              this.onToggleExpand(collection._id);
            }
          }}
          iconType="averPointIcon"
          linkClassName={iconClassName}
          iconClassName="mr-0"
          iconName={iconName}
          label=""
          iconSize={iconSize}
        />
        {/* ) : null} */}
      </div>
    );
  }

  renderSelectCheckbox(collection) {
    const { multiple, mode } = this.props;

    if (!multiple || (mode === 'ONLY_POINTS' && collection.isCollection)) {
      return;
    }

    const isSelected = this.isCollectionSelected(collection);
    return (
      <div
        className="d-inline-flex align-items-center flex-shrink-0"
        style={{ width: '25px' }}
      >
        <input type="checkbox" className="m-0" checked={isSelected} />
      </div>
    );
  }

  renderSubPoints(collection, level) {
    if (!collection.subPoints || !collection.subPoints.length) {
      return;
    }

    let isExpanded = this.isCollectionExpanded(collection);

    if (isExpanded) {
      let subCollectionsElm;

      let subCollections = [...collection.subPoints]
        .reverse()
        .map((subPoint) => {
          let pointObject = this.props.points[subPoint.pointId];

          // Do not show point with source URL (Articles)
          if (
            pointObject &&
            (this.props.showSubPointsWithSourceURL ||
              !pointObject.point.sourceURL)
          ) {
            return pointObject.point;
          } else {
            return null;
          }
        })
        .filter((i) => i);

      if (
        subCollections.length === collection.subPoints.length ||
        this.checkedCollectionIds.includes(collection._id)
      ) {
        subCollectionsElm = this.renderCollections(subCollections, level + 1);
      } else {
        if (collection.isCollection) {
          this.props.actions.getCollection(collection._id);
        } else {
          this.props.actions.getPoint(collection._id);
        }

        this.checkedCollectionIds.push(collection._id);

        subCollectionsElm = (
          <p className="ml-4" style={{ paddingLeft: `${(level + 1) * 30}px` }}>
            Loading...
          </p>
        );
      }

      return (
        <div className="sub-collections bg-av-light-gray">
          {subCollectionsElm}
        </div>
      );
    }
  }

  renderPublicIcon(collection) {
    return (
      <Icon
        icon={
          (collection.isCollection && collection.status === 'public') ||
          (!collection.isCollection && collection.status !== 'private')
            ? ICONS.GLOBE
            : ICONS.LOCK
        }
        size={12}
        className="flex-shrink-0"
      />
    );
  }

  renderSubPointCount(collection) {
    const { showSubPointCount } = this.props;

    if (!showSubPointCount) {
      return null;
    }

    return (
      <div
        className="gray-3 mr-3 font-size-12"
        style={{ whiteSpace: 'nowrap' }}
      >
        {collection.totalSubPoints} Points
      </div>
    );
  }

  renderPreviewIcon(collection) {
    const { showPointPreview, user } = this.props;

    if (!showPointPreview || collection.isCollection) {
      return null;
    }

    const popoverBottom = (
      <Popover className="import-point-point-card-preview">
        <PointCard point={collection} user={user} cardType="preview" />
      </Popover>
    );

    return (
      <div
        className="d-inline-flex mr-3 flex-shrink-0"
        onClick={(e) => e.stopPropagation()}
      >
        <OverlayTrigger
          trigger="click"
          rootClose
          placement="bottom"
          overlay={popoverBottom}
        >
          <Icon icon={ICONS.PREVIEW} className="av-blue" />
        </OverlayTrigger>
      </div>
    );
  }

  renderCollectionIcon(collection) {
    const className = classnames(
      'status av-gray flex-shrink-0 d-inline-flex align-items-center justify-content-center',
      {
        collection: collection.isCollection,
        point: !collection.isCollection,
      },
    );
    const collectionIconClassName = classnames(
      'av-gray flex-shrink-0 d-inline-flex align-items-center justify-content-center',
    );
    return (
      <div className={className}>
        <div>
          <Icon
            icon={
              collection.isCollection ? ICONS.COLLECTION_EXPAND : ICONS.BULLET
            }
            className={collectionIconClassName}
            size={collection.isCollection ? 18 : 10}
          />
        </div>
      </div>
    );
  }

  renderCollectionText(collection) {
    if (collection.text) {
      return collection.text;
    } else if (collection.sourceText) {
      return `"${collection.sourceText}"`;
    } else if (collection.sourceTitle) {
      return `"${collection.sourceTitle}"`;
    } else if (collection.sourceName) {
      return <i>{collection.sourceName}</i>;
    }
  }

  renderCollection(collection, level) {
    let className = classnames(
      'd-flex align-items-center collection pr-3 pt-2 pb-2',
      {
        selected: this.isCollectionSelected(collection),
      },
    );

    const style = {
      marginLeft: level === 0 ? 0 : '30px',
    };
    return (
      <div
        key={collection._id}
        className="position-relative"
        style={style}
        id={`collection-tree-${collection._id}`}
      >
        <div
          className={className}
          onClick={() => {
            this.onSelect(collection);
          }}
          style={{ minWidth: 0 }}
        >
          {this.renderCollapseExpandButton(collection)}
          {this.renderSelectCheckbox(collection)}
          <div
            className="flex-fill d-flex align-items-center mr-3"
            style={{ minWidth: 0 }}
          >
            {/* {this.renderCollectionIcon(collection)} */}
            <div
              className="flex-fill"
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {this.renderCollectionText(collection)}
            </div>
          </div>
          {this.renderSubPointCount(collection)}
          {this.renderPreviewIcon(collection)}
          {this.renderPublicIcon(collection)}
        </div>
        {this.renderSubPoints(collection, level)}
      </div>
    );
  }

  renderCollections(collections, level = 0) {
    const { mode } = this.props;

    if (mode === 'ONLY_COLLECTIONS') {
      collections = collections.filter((i) => i.isCollection);
    } else if (mode === 'ONLY_MEDIA_COLLECTIONS') {
      collections = collections.filter(
        (i) => i.isCollection && i.collectionType === 'media',
      );
    }

    let collectionElms = collections.map((collection) =>
      this.renderCollection(collection, level),
    );

    // return <div className="flex-fill collections">{collectionElms}</div>;
    return collectionElms;
  }

  renderGroupCollapseExpandButton(group) {
    let isExpanded = this.isGroupExpanded(group);
    const className = classnames(
      'expand flex-shrink-0 d-inline-flex justify-content-center align-items-center',
      {
        expanded: isExpanded,
      },
    );
    const iconClassName = classnames({
      add: !isExpanded,
      minus: isExpanded,
    });

    let iconName;

    if (group.collections && group.collections.length) {
      iconName = isExpanded ? 'GROUP_COLLAPSE' : 'GROUP_EXPAND';
    } else {
      iconName = 'GROUP_EMPTY';
    }

    return (
      <div className={className}>
        {group.collections && group.collections.length ? (
          <ActionLink
            onClick={() => {
              this.onGroupToggleExpand(group._id);
            }}
            linkClassName={iconClassName}
            iconType="averPointIcon"
            iconClassName="av-blue"
            iconName={iconName}
            label=""
            iconSize={22}
          />
        ) : null}
      </div>
    );
  }

  renderGroupIcon() {
    let iconClassName = classnames('flex-shrink-0');
    return (
      <div className="status flex-shrink-0 d-inline-flex align-items-center justify-content-center">
        <Icon icon={ICONS.USERS} className={iconClassName} size={18} />
      </div>
    );
  }

  renderGroup(group, level) {
    let collectionElms;

    if (this.isGroupExpanded(group)) {
      if (group.collections && group.collections.length) {
        const collections = group.collections
          .map((id) => {
            const collection = this.props.points[id];
            return collection ? collection.point : null;
          })
          .filter((i) => !!i);

        if (collections.length === group.collections.length) {
          collectionElms = collections.map((collection) =>
            this.renderCollection(collection, level + 1),
          );
        } else {
          if (this.checkedGroupIds.indexOf(group._id) === -1) {
            this.props.actions.getGroupCollections(group._id);
            this.checkedGroupIds.push(group._id);
          }

          collectionElms = <div className="ml-4">Loading...</div>;
        }
      }
    }

    const className = classnames('d-flex pt-2 pb-2 collection', {
      disabled: !group.collections || !group.collections.length,
    });
    return (
      <div className="position-relative" key={group._id}>
        <div
          className={className}
          onClick={(e) => {
            this.onGroupToggleExpand(group._id);
            e.stopPropagation();
          }}
        >
          {this.renderGroupCollapseExpandButton(group)}
          {/* {this.renderGroupIcon()} */}
          <div
            className="flex-fill"
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {group.name}
          </div>
        </div>
        <div className="collections">{collectionElms}</div>
      </div>
    );
  }

  renderGroups(groups, level = 0) {
    const groupElms = groups.map((group) => {
      return this.renderGroup(group, level);
    });

    return (
      <div className="collections">
        {/* <div className="p-2 av-blue">My Groups</div> */}
        {groupElms}
      </div>
    );
  }

  renderLoading() {
    return (
      <div className="text-center my-2">
        <div
          className="fa fa-spin fa-spinner text-primary"
          style={{ fontSize: '20px' }}
        />
      </div>
    );
  }

  renderCollectionTreeWithSearch() {
    const { activeSearch, isCollectionPointsLoaded } = this.props;
    const { type } = this.state;

    let list;

    if (type === 'MY_COLLECTIONS') {
      const collections = (
        this.props.searchCollectionPoints || this.props.collections
      )
        // Do not show writer prompt article media collections
        .filter((i) => {
          return !i.sourceURL || i.sourceURL.indexOf('/writer?promptId') === -1;
        });
      // .filter((i) => {
      //   return (
      //     (i.text || '')
      //       .toLowerCase()
      //       .indexOf(this.state.searchTerm.toLowerCase()) !== -1
      //   );
      // });
      list = this.renderCollections(collections);
    } else {
      const groups = this.props.groups.filter((i) => {
        return (
          (i.name || '')
            .toLowerCase()
            .indexOf(this.state.searchTerm.toLowerCase()) !== -1
        );
      });
      list = this.renderGroups(groups);
    }

    return (
      <React.Fragment>
        {this.renderSearch()}
        {this.renderTypes()}
        <div className="flex-fill collections">
          {activeSearch || !isCollectionPointsLoaded
            ? this.renderLoading()
            : list}
        </div>
      </React.Fragment>
    );
  }

  renderCreateCollection() {
    const { onNewCollection } = this.props;

    if (!onNewCollection) {
      return null;
    }

    return (
      <div
        className="d-flex align-items-center pt-2 pb-2"
        style={{ cursor: 'pointer' }}
        onClick={onNewCollection}
      >
        <Icon icon={ICONS.ADD} size={12} className="av-blue mr-2" />
        New Collection
      </div>
    );
  }

  render() {
    const className = classnames(
      'collection-tree d-flex flex-column',
      this.props.className,
    );
    return (
      <div className={className}>{this.renderCollectionTreeWithSearch()}</div>
    );
  }
}

let mapStateToProps = (state) => {
  const { authenticated } = state.user;
  const { user } = state.user;
  let collections = [];
  let activeSearch = false;

  if (authenticated) {
    const searchCollectionPoints =
      state.search[
        user.username === 'averpoint' ? 'allAverPoint' : 'portfolio'
      ];
    activeSearch = searchCollectionPoints
      ? searchCollectionPoints.activeSearch
      : false;

    if (searchCollectionPoints && searchCollectionPoints.searchTerm) {
      searchCollectionPoints.collectionPoints.forEach((collectionPointId) => {
        const collectionPoint = state.points[collectionPointId];

        if (!collectionPoint) {
          return;
        }

        collections.push(collectionPoint.point);
      });
      // Display priority
      // 1. Collection without source URL
      // 2. Collection with source URL
      collections = collections.sort((a) => (!a.sourceURL ? -1 : 1));
      searchCollectionPoints.points.forEach((collectionPointId) => {
        const collectionPoint = state.points[collectionPointId];

        if (!collectionPoint || !collectionPoint.sourceURL) {
          return;
        }

        collections.push(collectionPoint.point);
      });
    } else if (state.portfolios[user.username]) {
      const portfolioObject = state.portfolios[user.username];
      portfolioObject.portfolio.collectionPoints.forEach(
        (collectionPointId) => {
          const collectionPoint = state.points[collectionPointId];
          if (!collectionPoint) return;
          if (
            collectionPoint.permissionLevel !== 'write' ||
            collectionPoint.invitationStatus !== 'accepted'
          ) {
            return;
          }

          collections.push(collectionPoint.point);
        },
      );
    } else {
      console.log('Error - trying to add to collection but not logged in');
    }
  }

  // const searchCollectionPoints = state.search.collectionTree.collectionPoints
  //   .concat(state.search.collectionTree.points)
  //   .map(i => state.points[i].point);

  return {
    user,
    collections,
    points: state.points,
    groups: state.groups.mine.map(
      (groupdId) => state.groups.entities[groupdId],
    ),
    activeSearch,
    isCollectionPointsLoaded:
      state.portfolios[user.username] &&
      state.portfolios[user.username].isCollectionPointsLoaded,
  };
};

let mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CollectionTree);
